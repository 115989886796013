<template>
    <div id="toolbar-general-component" data-cy="toolbar-component">
        <v-toolbar color="secondary" flat :height="is_mobile ? '70px' : '80px'">
            <v-layout class="justify-start">
                <span class="font-20 color-white-fix">{{ toolbar_title }}</span>
            </v-layout>

            <v-layout v-if="!is_mobile" class="justify-end">
                <div class="nav-grid">
                    <div>
                        <v-icon class="pointer" color="primary" size="30"
                                @click="$refs.DialogContactComponent.dialog_contact = true"
                                v-if="$store.getters['user_connected/user_connected'].id_professional === 14"
                        >
                            mdi-lifebuoy
                        </v-icon>
                    </div>
                    <div class="size-30" @click="navigateTo('notifications')">
                        <v-badge :value="$store.getters['notifications/total_notifications_not_see']"
                                 :content="$store.getters['notifications/total_notifications_not_see']"
                                 class="badge-notification-lower-size" color="error" overlap light
                        >
                            <v-icon class="mx-auto" color="primary" size="30"
                                    @click="navigateTo('notifications')">
                                mdi-bell
                            </v-icon>
                        </v-badge>
                    </div>
                    <div class="pointer" @click="$refs.dialogProfileComponent.dialog_profile = true">
                        <profile-picture-component/>
                    </div>

                    <span>
                       <span class="font-12 pointer color-white-fix"
                             @click="$refs.DialogContactComponent.dialog_contact = true"
                             v-if="$store.getters['user_connected/user_connected'].id_professional === 14"
                       >
                            {{ $t('left_navbar_component.help') }}
                        </span>
                    </span>
                    <span class="font-12 pointer color-white-fix" @click="navigateTo('notifications')">
                        {{ $t('left_navbar_component.notifications') }}
                    </span>
                    <span class="font-12 pointer color-white-fix"
                          @click="$refs.dialogProfileComponent.dialog_profile = true">
                        {{ $t('left_navbar_component.profile') }}
                    </span>
                </div>
            </v-layout>
        </v-toolbar>

        <dialog-profile-component ref="dialogProfileComponent"/>

        <dialog-contact-component ref="DialogContactComponent"/>
    </div>
</template>

<script>
import DialogProfileComponent from "@/components/Profile/DialogProfileComponent";
import DialogContactComponent from "@/components/Global/DialogContactComponent.vue";
import ProfilePictureComponent from "@/components/Profile/ProfilePictureComponent";

export default {
    name: "ToolbarComponent",
    components: {ProfilePictureComponent, DialogProfileComponent, DialogContactComponent},
    data() {
        return {
            asset_types: this.$store.getters['asset_types/asset_types']
        }
    },
    props: {
        title: {
            type: String,
            required: true
        }
    },
    computed: {
        toolbar_title() {
            let title = this.title;
            if (this.$route.name === 'equipment') {
                title = (this.asset_types.length > 1 || this.asset_types.length === 0 ? title : this.$t(`equipment_page.${this.asset_types[0].key}`))
            }

            return title
        }
    },
}
</script>

<style>
.notification-pin-bell-icon-desktop {
    position: absolute !important;
    top: 10px;
    right: 75px;
}

.badge-notification-toolbar-desktop .v-badge__badge {
    left: 15px !important;
}
</style>